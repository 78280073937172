import React from 'react';
import { capitalizeFirstLetter } from '../../../../common/helpers';
import { getLowerZeroArch, getUpperZeroArch, getUpperZeroWire, getLowerZeroWire } from '../../../../redux/reducers/bpp/wire_selection/wire_selection';
import { setWire, setArch } from '../../../../redux/actions/bpp/wire_selection/wire_selection';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const SwZeroArch = (props) => {
  const { arch, swZeroFiles } = props;
  const { setArch, setWire } = props;
  const file = swZeroFiles?.find((file) => file.original_filename.includes(arch));
  const wireTypes = [
    { type: 'Mini Tab', pn: '80010', checked: false },
    { type: 'Opening Loop', pn: '80011', checked: false },
    { type: 'Mini Tab and Opening Loop', pn: '80012', checked: false },
    { type: 'Experimental', pn: '', checked: false },
  ];

  const toggleArch = (e) => {
    const arch = e.target.value;
    const toggledValue = !props[`${arch}ZeroArch`];
    setArch(arch, toggledValue);
    if (!toggledValue) {
      setWire(arch, {});
    }
  };

  const onWireChange = (e) => {
    const { value } = e.target;
    const arch = e.target.dataset.arch;
    setWire(arch, value);
  };

  return (
    <div className="sw0-item">
      <div className="sw0-arch">
        <input type="checkbox" value={arch} name="wire-selection-radio" checked={props[`${arch}ZeroArch`]} onChange={toggleArch} />
        <label className="bold-text">{capitalizeFirstLetter(arch)}</label>{' '}
        {props[`${arch}ZeroArch`] && (
          <>
            {file ? (
              <span data-original-title="Remove DXF File" data-toggle="tooltip">
                <i
                  className="fa fa-trash"
                  onClick={() => {
                    props.onRemoveClick('Remove Manual Custom Wire', 'ws_manual', file.upload_data);
                  }}
                />
              </span>
            ) : (
              <span data-original-title="Upload DXF File" data-toggle="tooltip">
                <i
                  className="fa fa-upload"
                  onClick={() => {
                    props.onUploadClick(`${arch}_zero`, 'Manual Custom Wire', 'ws_dxf', props.redo, props.rev);
                  }}
                />
              </span>
            )}
          </>
        )}
      </div>
      {props[`${arch}ZeroArch`] && !file ? (
        <div className="sw0-types">
          {wireTypes.map((wire, index) => {
            return (
              <div key={index}>
                <input type="radio" data-arch={arch} value={wire.pn} checked={props[`${arch}ZeroWire`]?.pn === wire.pn} onChange={onWireChange} />
                <label>{wire.type}</label>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    upperZeroArch: getUpperZeroArch(state),
    lowerZeroArch: getLowerZeroArch(state),
    upperZeroWire: getUpperZeroWire(state),
    lowerZeroWire: getLowerZeroWire(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setWire: setWire,
      setArch: setArch,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SwZeroArch);

import React from 'react';
import cn from 'classnames';

import { ToothIconDefault, ToothIconHover, ToothIconSelected } from '../../assets/icons';

import './rating_form.scss';

export const ToothIcon = ({ selected, hover, ...props }) => {
  if (selected) {
    return <ToothIconSelected {...props} />;
  }
  if (hover) {
    return <ToothIconHover {...props} />;
  }
  return <ToothIconDefault {...props} />;
};

const toothLabels = {
  1: 'Totally Dissatisfied',
  2: 'Dissatisfied',
  3: 'Neutral',
  4: 'Satisfied',
  5: 'Totally Satisfied',
};

export const ToothRating = ({ label, rating, disabled, onClick, showToothLabels = false, centered = false }) => {
  const [hover, setHover] = React.useState(0);

  const getToothLabel = (i) => {
    return toothLabels[i] ?? '';
  };

  const toothRatingTeethClassNames = cn('tooth-rating__teeth', {
    'tooth-rating__teeth--show-labels': showToothLabels,
    'tooth-rating__teeth--centered': centered,
  });

  const getToothIconClassNames =  cn('teeth__tooth-icon', {
    'fixed-width': showToothLabels,
  });

  const getToothIconLabelClassNames = (i) => {
    return cn('tooth-icon__label', {
      'tooth-icon__label--selected': i === hover || i === rating,
      'tooth-icon__label--disabled': disabled,
    });
  };

  const handleMouseEnter = (i) => {
    if (!disabled) {
      setHover(i);
    }
  };

  const handleMouseLeave = () => {
    if (!disabled) {
      setHover(0);
    }
  };

  const handleClick = (i) => {
    if (!disabled) {
      onClick(i);
    }
  };

  return (
    <div className="tooth-rating">
      <h4 className="tooth-rating__label">{label}</h4>
      <div className={toothRatingTeethClassNames}>
        {[1, 2, 3, 4, 5].map((i) => (
          <div key={`tooth_icon_${i}`} className={getToothIconClassNames}>
            <ToothIcon
              key={i}
              selected={i <= rating}
              hover={i <= hover}
              onMouseEnter={() => handleMouseEnter(i)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleClick(i)}
            />
            {showToothLabels ? (
              <p
                className={getToothIconLabelClassNames(i)}
                onClick={() => handleClick(i)}
                onMouseEnter={() => handleMouseEnter(i)}
                onMouseLeave={handleMouseLeave}
              >
                {getToothLabel(i)}
              </p>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};

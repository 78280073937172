import { getCaseIDFromStageIZip } from '../../common/helpers';
import { removeCaseIdInitialNumber, removeIreqIdInitialNumber } from '../../common/functions';

/**
 * Checks if existing file is a 3matic zip
 * @param {File} file
 * @returns {Boolean} 3matic File Status
 */
const isThreeMaticFile = (file) => {
  return file && ['ddm_upload', 'ifu_upload', 'bmf_upload'].includes(file.file_type);
};

/**
 * Validates File name on upload for STL Files
 * @param {List - Objects} files
 * @param {Object} props
 * @returns {Boolean} File Name Valid Status
 */
const isValidFileName = (files, props) => {
  let is_valid = true;
  if (props.folder === 'repaired_scans') {
    for (let i = 0; i < files.length; i++) {
      let is_new_valid = this.props.fileNameNotValid(files[i].name);
      if (!is_new_valid) {
        is_valid = is_new_valid;
      }
    }
  }
  return is_valid;
};

/**
 * Determines if Stage I upload files have correct file name format
 * @function
 * @param {String} case_id - Case id
 * @param {Array} files - List of files to upload
 * @returns {Boolean} - True or False
 */
const hasValidStageIFileName = (case_id, files, props) => {
  if ((props.folder === 'ifu_upload' || props.folder === 'ddm_upload') && files.length > 0) {
    const file_name_case_id = getCaseIDFromStageIZip(files[0]['name']);
    if (!file_name_case_id) {
      if (props.folder === 'ifu_upload') {
        props.setIFUIncorrectFileNameError('format', true);
      } else {
        props.setDDMIncorrectFileNameError('format', true);
      }
      return false;
    }
    if (file_name_case_id && file_name_case_id !== case_id) {
      if (props.folder === 'ifu_upload') {
        props.setIFUIncorrectFileNameError('case_id', true);
      } else {
        props.setDDMIncorrectFileNameError('case_id', true);
      }
      return false;
    }
  }
  return true;
};

/**
 * Determines if DXF upload files have correct file name format
 * @function
 * @param {string} case_id - Case id
 * @param {array} files - List of files to upload
 * @returns {boolean} - True or False
 */
const hasValidDxfFileName = (case_id, files, props) => {
  let has_correct_case_id = null;
  let has_correct_wire = null;
  const is_ir = case_id.includes('-IR');
  let separated_name = files[0]?.name?.split('_');

  if (files.length > 0) {
    if (separated_name && separated_name.length > 3) {
      separated_name = separated_name.slice(0, 3);

      if (is_ir) {
        case_id = removeIreqIdInitialNumber(case_id);
        const nomenclature = {
          0: 'zero',
          1: 'initial',
          2: 'intermediate',
          3: 'final',
        };
        const location = props.location.replace(/ /g, '').split('_');
        has_correct_wire = separated_name[1] === location[2] && separated_name[2] === nomenclature[location[3]];
      } else {
        has_correct_wire = `${separated_name[1]}_${separated_name[2]}` === props.wire_name;
      }
      const filename_case_id = separated_name[0].endsWith('-DE1') ? separated_name[0].replace('-DE1', '-DE') : separated_name[0];
      has_correct_case_id = filename_case_id === case_id;
      if (has_correct_case_id && has_correct_wire) {
        return true;
      }
    }
  }
  const error_type = has_correct_wire === false ? 'format' : has_correct_case_id === false ? 'case_id' : 'format';
  props.setDxfFileNameError(true, error_type, props.wire_name);
  return false;
};

const validateSIDBfilename = (files, props) => {
  if (files.length > 0) {
    const filename = files[0].name;
    let [name, ext] = filename.split('.');
    const id = removeCaseIdInitialNumber(removeIreqIdInitialNumber(props.id));
    const correct_name = `${id}_idb_final`;
    let error = '';
    if (name !== correct_name) {
      error = `Incorrect File Name. File name should be ${correct_name}`;
    } else if (ext !== 'zip') {
      error = 'Incorrect file format';
    }
    return error;
  }
};

/**
 * Validate filename for Custom Work Order
 * @param {Object} data Upload Data
 * @returns
 */
const validateCustomWo = (files, props) => {
  const filename = files.length ? files[0].name.replace(/\.[^/.]+$/, '').toLowerCase() : '';
  if (filename) {
    if (hasDuplicateWo(filename, props)) return false;
    const split_name = filename.split(' - ');
    if (split_name.length !== 2) {
      props.setCustomWoFileNameError(true, 'format');
      return false;
    }
    // eslint-disable-next-line
    const [case_id, wo_num] = split_name;
    const regexp = /^WO\S*-\S*$/gi;
    if (!regexp.test(wo_num)) {
      props.setCustomWoFileNameError(true, 'format');
      return false;
    } else if (removeCaseIdInitialNumber(props.id).toLowerCase() !== case_id) {
      props.setCustomWoFileNameError(true, 'case_id');
      return false;
    } else if (wo_num.length > 20) {
      props.setCustomWoFileNameError(true, 'length');
      return false;
    }
    props.setCustomWoFileNameError(false, '');
    return true;
  }
  return false;
};

/**
 * Checks file name against existing work order values
 * @param {String} file_name - File name
 * @param {Object} props - Props object
 * @returns {Boolean}
 */
const hasDuplicateWo = (file_name, props) => {
  const filename = file_name.split(' - ')[1];
  for (let wo of props.ws_wo_history) {
    if (wo.toLowerCase() === filename) {
      props.setCustomWoFileNameError(true, 'duplicate');
      return true;
    }
  }
  return false;
};

/**
 * Checks if any files uploaded has no data
 * @param {File[]} files
 * @param {{ onEmptyFileError: () => void }} props
 */
const hasData = (files, props) => {
  const isFileEmpty = file => file.size === 0 && props.onEmptyFileError;
  return !files.some(isFileEmpty);
};

/**
 * Determines if ws guide upload has correct file name format
 * @function
 * @param {string} case_id - Case id
 * @param {array} files - List of files to upload
 * @returns {boolean} - True or False
 */
const validateWsGuide = (case_id, files, props) => {
  let filename = files.length > 0 ? files[0].name : '';
  if (filename) {
    filename = filename.substr(0, filename.indexOf('.'));
    const split_name = filename.split(' - ');
    if (split_name.length !== 2) {
      props.setWsGuideFileNameError(true);
      return false;
    }
    const file_case_id = split_name[0];
    const ws_guide_name = split_name[1];
    if (file_case_id !== removeIreqIdInitialNumber(case_id) || ws_guide_name !== 'Wire Selection Guide Form') {
      props.setWsGuideFileNameError(true);
      return false;
    }
  }
  return true;
};

const UNKNOWN_MIME = 'unknown';
const ExtToMimeTypeMap = {
  stl: 'model/stl',
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  tif: 'image/tif',
  tiff: 'image/tiff',
  bmp: 'image/bmp',
  gif: 'image/gif',
  pdf: 'application/pdf',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xlsm: 'application/vnd.ms-excel.sheet.macroEnabled.12',
  zip: 'application/zip',
};
/**
 * Gets file mimetype
 * @param {String} fileName
 * @return {String}
 */
const getMimeByFilename = (fileName) => {
  const dotIndex = fileName.lastIndexOf('.');
  const ext = fileName.substring(dotIndex + 1, fileName.length);
  return ExtToMimeTypeMap[ext] || UNKNOWN_MIME;
};

/**
 * Waits time specified in ms
 * @param {Number} ms
 * @return {Promise<void>}
 */
const waitMs = async (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export {
  isThreeMaticFile,
  hasData,
  validateCustomWo,
  hasValidDxfFileName,
  hasValidStageIFileName,
  isValidFileName,
  validateWsGuide,
  validateSIDBfilename,
  getMimeByFilename,
  waitMs,
};

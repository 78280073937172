import React from 'react';
import './sw_zero.scss';
import SwZeroArch from './arch';

const SwZero = (props) => {
  const { onDownload, onUploadClick } = props;

  return (
    <div className="wire-selection-review-item">
      <div className="bold-text">Smartwire 0</div>
      <form>
        <SwZeroArch {...props} arch="upper" onUploadClick={onUploadClick} onDownload={onDownload} />
        <SwZeroArch {...props} arch="lower" onUploadClick={onUploadClick} onDownload={onDownload} />
      </form>
    </div>
  );
};

export default SwZero;
